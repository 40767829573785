
import React, {useContext, useState} from 'react';
import { firebaseAuth } from '../provider/AuthProvider';
import { NavLink } from "react-router-dom";
import "./styles/Navigation.scss";

import { NavItem, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";


export default function AdminNavigation () {
    const { handleSignout } = useContext(firebaseAuth);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownTeamOpen, setDropdownTeamOpen] = useState(false);
    const [dropdownAllPropertiesOpen, setDropdownAllPropertiesOpen] = useState(false);


    const toggle = () => setDropdownOpen(!dropdownOpen);
    const toggleTeam = () => setDropdownTeamOpen(!dropdownTeamOpen);
    const toggleAllProperties = () => setDropdownAllPropertiesOpen(!dropdownAllPropertiesOpen);



    const activeNavStyle = {
        fontWeight: "bold",
        fontStyle: "italic"
        };

    return (
        <>
          <NavItem>
            <span className="nav-link">
              <NavLink exact to="/" activeStyle={activeNavStyle}>
                Home
              </NavLink>
            </span>
          </NavItem>
          <Dropdown nav isOpen={dropdownAllPropertiesOpen} toggle={toggleAllProperties}>
          <DropdownToggle nav caret>
            Properties
          </DropdownToggle>
          <DropdownMenu>
          <DropdownItem> <span className="nav-link-span">
              <NavLink to="/all-properties" activeStyle={activeNavStyle}>
                All Properties
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/properties/add-property" activeStyle={activeNavStyle}>
                Add Property
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/properties/edit-blurb" activeStyle={activeNavStyle}>
                Edit All Properties Blurb
              </NavLink>
            </span></DropdownItem>
          </DropdownMenu>
        </Dropdown>
      <NavItem>
      <span className="nav-link">
              <NavLink to="/edit-about" activeStyle={activeNavStyle}>
                Edit About
              </NavLink>
            </span>
            </NavItem>
        <Dropdown nav isOpen={dropdownTeamOpen} toggle={toggleTeam}>
          <DropdownToggle nav caret>
            Edit Team
          </DropdownToggle>
          <DropdownMenu>
          <DropdownItem> <span className="nav-link-span">
              <NavLink to="/team/edit-team" activeStyle={activeNavStyle}>
                Edit Team Overview
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/team/all-team-members" activeStyle={activeNavStyle}>
                All Team Members
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/team/add-senior-team" activeStyle={activeNavStyle}>
                Add Senior Team Member
              </NavLink>
            </span></DropdownItem>
            <DropdownItem> <span className="nav-link-span">
              <NavLink to="/team/add-additional-team" activeStyle={activeNavStyle}>
                Add Team Member
              </NavLink>
            </span></DropdownItem>
          </DropdownMenu>
        </Dropdown>
          <NavItem>
            <span className="nav-link">
              <NavLink to="/edit-tenants" activeStyle={activeNavStyle}>
                Edit Tenants
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink to="/edit-careers" activeStyle={activeNavStyle}>
                Edit Careers
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink to="/edit-contact" activeStyle={activeNavStyle}>
                Edit Contact
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink exact to="/users/my-account" activeStyle={activeNavStyle} onClick={handleSignout}>
                My Account
              </NavLink>
            </span>
          </NavItem>
          <NavItem>
            <span className="nav-link">
              <NavLink exact to="/" activeStyle={activeNavStyle} onClick={handleSignout}>
                Logout
              </NavLink>
            </span>
          </NavItem>
        </>
      );
    
}
