import React from "react";
import { companyMethods } from "../../firebase/companyMethods";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from "reactstrap";

import '../styles/Properties.scss';


class AllTeamMembers extends React.Component {
  state = {
    seniorTeam: [],
    additionalTeam: []
  };

  componentDidMount() {
    companyMethods.getSeniorTeam().then((data) => {
      this.setState({
        seniorTeam: data
          });
    });
    companyMethods.getAdditionalTeam().then((data) => {
        this.setState({
          additionalTeam: data
        });
      });
  }

  deleteTeamMember = (teamMemberID) => {
    companyMethods.deleteTeamMember(teamMemberID);

 }

 deleteSeniorTeamMember = (teamMemberID) => {
  companyMethods.deleteSeniorTeamMember(teamMemberID);

}


  render() {
    const {
      seniorTeam,
      additionalTeam
    } = this.state;

    return (
        <Container className="adminContainer">
        <h2>All Team Members</h2>

        {seniorTeam.map((val) => {
        const teamMemberPath = val.fullName.split(' ').join('_');
          return (
            <Row className="propertyRow propertyRowAll justify-content-md-center" key={val.teamMemberID}>
              <Col
                sm="12"
                md="4"
                className="propertyMobile"
                key={val.teamMemberID}
              >
                <div style={{marginBottom: "10px"}}>
                  <img className="img-fluid" src={val.photos[0]} alt=""/>
                </div>
              </Col>
              <Col sm="12" md="8">
                <div className="allPropertiesName">
                  <h2>{val.fullName}</h2>
                </div>
                <hr className="solid" />
                <div className="propertyDiv">

                </div>
                <div className="propertyAddress">
                  {val.role}
                </div>
                <div>{val.bio}</div>
                <div className="propertyRight">
                     <Link
              to={{
                pathname: "edit-senior-member/" + teamMemberPath + "/" + val.teamMemberID,
                teamMemberID: {
                  name: val.teamMemberID,
                  path: val.fullName
                },
              }}
            >
                <span className="btn btn-secondary smallerButton" style={{marginRight: '10px'}}>Edit</span>
              </Link>
              <Button className="btn btn-secondary smallerButton" onClick={() => this.deleteSeniorTeamMember(val.teamMemberID)}>Delete</Button>
                </div>
              </Col>
            </Row>
          );
        })}
        {additionalTeam.map((val) => {
        const teamMemberPath = val.fullName.split(' ').join('_');
          return (
            <Row className="propertyRow propertyRowAll justify-content-md-center" key={val.teamMemberID}>
              <Col
                sm="12"
                md="4"
                className="propertyMobile"
                key={val.teamMemberID}
              >
                <div style={{marginBottom: "10px"}}>
                  <img className="img-fluid" src={val.photos[0]} alt="" />
                </div>
              </Col>
              <Col sm="12" md="8">
                <div className="allPropertiesName">
                  <h2>{val.fullName}</h2>
                </div>
                <hr className="solid" />
                <div className="propertyDiv">

                </div>
                <div className="propertyAddress">
                  {val.role}
                </div>
                <div>{val.bio}</div>
                <div className="propertyRight">
                     <Link
              to={{
                pathname: "edit-team-member/" + teamMemberPath + "/" + val.teamMemberID,
                teamMemberID: {
                  name: val.teamMemberID,
                  path: val.fullName
                },
              }}
            >
                <span className="btn btn-secondary smallerButton" style={{marginRight: '10px'}}>Edit</span>
              </Link>
              <Button className="btn btn-secondary smallerButton" onClick={() => this.deleteTeamMember(val.teamMemberID)}>Delete</Button>

                </div>
              </Col>
            </Row>
          );
        })}
      </Container>
    );
  }
}
export default AllTeamMembers;
