import React, { useContext } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import "./styles/SignIn.scss";

const SignIn = () => {
  const { handleSignin, inputs, setInputs, errors } = useContext(firebaseAuth);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignin();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div className="card card-signin my-5">
            <div className="card-body">
              <h5 className="card-title text-center">Sign In</h5>
              <form className="form-signin" onSubmit={handleSubmit}>
                <div className="form-label-group">
                  <input
                    type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    required
                    onChange={handleChange}
                    name="email"
                    value={inputs.email}
                  />
                  <label htmlFor="inputEmail">Email address</label>
                </div>

                <div className="form-label-group">
                  <input
                    type="password"
                    id="inputPassword"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Password"
                    required
                    name="password"
                    value={inputs.password}
                  />
                  <label htmlFor="inputPassword">Password</label>
                </div>
                {errors.length > 0
                  ? errors.map((error) => (
                      <p style={{ color: "red" }}>{error}</p>
                    ))
                  : null}
                <button
                  className="btn btn-lg btn-primary btn-block text-uppercase"
                  type="submit"
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
