import React from "react";
import { companyMethods } from "../../firebase/companyMethods";

import { Col, Button, Form, FormGroup, Label, Input, Row, Container } from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

class AddProperty extends React.Component {
  state = {
    propertyName: "",
    link: "",
    address: "",
    description: "",
    leasingName: "",
    leasingEmail: "",
    city: "",
    state: "",
    zip: "",
    vacancies: "",
    maxsq: "",
    moveInDate: "",
    parkingSpaces: "",
    imageFiles: [],
    invalidInput: false,
    commercial: false,
    residential: false,
  };

  onFieldChange = (field, e) => {
    this.setState({
      [field]: e.target.value,
    });
  };

  onPropertyTypeChange = (field, e) => {
    if(e.target.value === "commercial"){
        this.setState({
            commercial: true,
            residential: false
          });

    } else {
        this.setState({
            commercial: false,
            residential: true
          });
    }
};

  onDrop = (files, allFiles) => {
    const newFiles = files["meta"];
    if (newFiles["status"] === "done") {
      this.setState((prevState) => {
        const newFiles = files["meta"];
        return {
          imageFiles: [...prevState.imageFiles, newFiles],
        };
      });
      console.log(this.state.imageFiles);
    } else if (newFiles["status"] === "removed") {
      this.setState((prevState) => {
        const removedFileState = this.state.imageFiles.filter(
          (file) => file["name"] !== newFiles["name"]
        );
        return {
          imageFiles: removedFileState,
        };
      });
    }
  };

  handleSubmit = (e) => {
      const {
           propertyName,
           city,
           state,
           moveInDate,
           maxsq,
           description,
           address,
           zip
      } = this.state;
    e.preventDefault();
    const randomString = Math.random().toString(36).slice(2);

    const property = {
      propertyID: randomString,
      propertyName: this.state.propertyName,
      link: this.state.link,
      address: this.state.address,
      description: this.state.description,
      leasingEmail: this.state.leasingEmail,
      leasingName: this.state.leasingName,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      vacancies: this.state.vacancies,
      maxsq: this.state.maxsq,
      parkingSpaces: this.state.parkingSpaces,
      moveInDate: this.state.moveInDate,
      imageFiles: this.state.imageFiles,
      commercial: this.state.commercial,
      residential: this.state.residential,
    };
    const isNotEmpty = Object.values(property).every(
      (value) => value !== null && value !== ""
    );
    const imageFilesNotEmpty = Object.values(property["imageFiles"]).length > 0;

    if (propertyName.length === 0 || description.length === 0 || maxsq.length === 0 || address.length === 0 || city.length === 0 || moveInDate.length === 0
        || zip.length === 0 || state.length === 0 && imageFilesNotEmpty) {
      this.setState({
        invalidInput: true
      });
    } else if (!isNotEmpty || !imageFilesNotEmpty) {
      this.setState({
        invalidInput: false
      });
      companyMethods.addNewProperty(property);
    }
  };

  render() {
    const invalidInput = this.state.invalidInput;
    return (
        <Container className="adminContainer">
        <h2>Add Property</h2>
        {invalidInput && (
          <div style={{color: "red"}}>Property name, description, address, city, state, zip, max square feet, move in date and photo are all required. Please check those fields and submit again.</div>
        )}
        <Form className="w-100" onSubmit={this.handleSubmit}>
          <Row form className="signup-form">
            <Col className="signup-form" md={6}>
              <FormGroup>
                <Label for="name">Property Name</Label>
                <Input
                  type="text"
                  name="propertyName"
                  placeholder="Property Name"
                  onChange={this.onFieldChange.bind(null, "propertyName")}
                />
              </FormGroup>
              <FormGroup tag="fieldset">
                <Label for="name">Property Type</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="propertyType"
                      value="commercial"
                      onChange={this.onPropertyTypeChange.bind(null, "commercial")}
                    />{" "}
                    Commercial
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="propertyType"
                      value="residential"
                      onChange={this.onPropertyTypeChange.bind(null, "residential")}
                    />{" "}
                    Residential
                  </Label>
                </FormGroup>
              </FormGroup>
              <FormGroup>
                <Label for="link">Property Website Link</Label>
                <Input
                  type="text"
                  name="link"
                  placeholder="http://www.example.com"
                  onChange={this.onFieldChange.bind(null, "link")}
                />
              </FormGroup>
              <FormGroup>
                <Label for="address">Property Address</Label>
                <Input
                  type="text"
                  name="address"
                  placeholder="Property Address"
                  onChange={this.onFieldChange.bind(null, "address")}
                />
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="city">City</Label>
                    <Input
                      type="text"
                      name="city"
                      placeholder="City"
                      onChange={this.onFieldChange.bind(null, "city")}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="state">State</Label>
                    <Input
                      type="text"
                      name="state"
                      placeholder="state"
                      onChange={this.onFieldChange.bind(null, "state")}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="zip">Zip</Label>
                    <Input
                      type="text"
                      name="zip"
                      placeholder="zip"
                      onChange={this.onFieldChange.bind(null, "zip")}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="email">Leasing Contact Information</Label>
                <Input
                  type="text"
                  name="leasingName"
                  placeholder="Leasing Contact Name"
                  onChange={this.onFieldChange.bind(null, "leasingName")}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="leasingEmail"
                  placeholder="Leasing Contact Email"
                  onChange={this.onFieldChange.bind(null, "leasingEmail")}
                />
              </FormGroup>
            </Col>
            <Col className="signup-form" sm={12} md={6}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="maxsq">Total Sqft</Label>
                    <Input
                      type="text"
                      name="maxsq"
                      placeholder="Total Sqft"
                      onChange={this.onFieldChange.bind(null, "maxsq")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={5}>
                  <FormGroup>
                    <Label for="moveInDate">Move In Date</Label>
                    <Input
                      type="date"
                      name="MoveInDate"
                      placeholder="Move In Date"
                      onChange={this.onFieldChange.bind(null, "moveInDate")}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="parkingSpaces">Parking Spaces</Label>
                    <Input
                      type="text"
                      name="parkingSpaces"
                      placeholder="Parking Spaces"
                      onChange={this.onFieldChange.bind(null, "parkingSpaces")}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="Vacancies">Vacancies</Label>
                    <Input
                      type="text"
                      name="vacancies"
                      placeholder="Vacancies"
                      onChange={this.onFieldChange.bind(null, "vacancies")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="Description">Property Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      placeholder="Description"
                      onChange={this.onFieldChange.bind(null, "description")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="photo">Property Photo</Label>
              </FormGroup>
              <FormGroup>
                <Dropzone
                  multiple
                  inputContent="Drop Files Here"
                  styles={{
                    dropzone: { width: 550, height: 200 },
                    dropzoneActive: { borderColor: "green" },
                  }}
                  onChangeStatus={this.onDrop}
                />
              </FormGroup>
              <div className="submitButton" style={{ textAlign: "right" }}>
                <Button size="lg">Add Property</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}
export default AddProperty;
