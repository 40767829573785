import React, {useState, useEffect} from 'react';
import {authMethods} from '../firebase/authMethods';

export const firebaseAuth = React.createContext();

const AuthProvider = (props) => {
  const tokenIsSet = localStorage.getItem('token');
  const initState = {email: '', password: ''}
  const [inputs, setInputs] = useState(initState)
  const [errors, setErrors] = useState([])
  const [token, setToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(tokenIsSet);

  const handleSignup = () => {
    authMethods.signup(inputs.email, inputs.password,setErrors ,setToken);
  }
  const handleSignin = () => {
    authMethods.signin(inputs.email, inputs.password, setErrors, setToken);

  }
  const handleSignout = () => {
    authMethods.signout(setErrors, setToken);
    setIsLoggedIn(false);
  }

  useEffect(() => {
      if(tokenIsSet){
          setIsLoggedIn(true);
      } else {
          setIsLoggedIn(false);
      }
  }, [tokenIsSet, isLoggedIn]);

  return (
    <firebaseAuth.Provider
    value={{
      handleSignup,
      handleSignin,
      token,
      inputs,
      isLoggedIn,
      setInputs,
      errors,
      handleSignout
    }}>
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
