import React, { useContext } from 'react';
import { firebaseAuth } from './provider/AuthProvider';

import { Container }  from 'react-bootstrap';
import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Navigation from './static/Navigation';
import AdminHome from './static/AdminHome';
import Footer from './static/Footer';

import SignIn from './static/SignIn';



import AllProperties from './components/AllProperties';
import EditAbout from './components/EditAbout';
import EditCareers from './components/EditCareers';
import EditContact from './components/EditContact';
import EditTenants from './components/EditTenants';
import EditTeam from './components/EditTeam';
import AddProperty from './components/Properties/addProperty';
import EditProperty from './components/Properties/editProperty';
import MyAccount from './components/UserManagement/MyAcocunt';
import AddSeniorTeam from './components/Team/AddSeniorTeamMember';
import AddAdditionalTeam from './components/Team/AddAdditionalTeamMember';
import AllTeamMembers from './components/Team/AllTeamMembers';
import EditSeniorTeam from './components/Team/EditSeniorTeam';
import EditAdditionalTeam from './components/Team/EditAdditionalTeam';
import EditAllProperitesBlub from './components/Properties/EditAllPropertiesBlurb';


export default function App() {

  const isAdmin = true;
  const { isLoggedIn } = useContext(firebaseAuth);

  return (
   <Router>
      <div>
          {isAdmin && (<Navigation isAdmin={isAdmin} isLoggedIn={isLoggedIn}/>)}
          <Container fluid className="main" style={{padding:0}} >
          <Switch>
              <Route exact path="/" render={() => !isLoggedIn ? <SignIn /> : <AdminHome />} />
              <Route path="/all-properties" render={() => !isLoggedIn ? <SignIn /> : <AllProperties />} />
              <Route path="/edit-about" render={() => !isLoggedIn ? <SignIn /> : <EditAbout />} />

              <Route path="/edit-tenants" render={() => !isLoggedIn ? <SignIn /> : <EditTenants />} />
              <Route path="/edit-contact" render={() => !isLoggedIn ? <SignIn /> : <EditContact />} />
              <Route path="/edit-careers" render={() => !isLoggedIn ? <SignIn /> : <EditCareers />} />
              <Route path="/team/edit-team" render={() => !isLoggedIn ? <SignIn /> : <EditTeam />} />
              <Route path="/team/all-team-members" render={() => !isLoggedIn ? <SignIn /> : <AllTeamMembers />} />

              <Route path="/team/add-senior-team" render={() => !isLoggedIn ? <SignIn /> : <AddSeniorTeam />} />
              <Route path="/team/edit-senior-member" render={rProps => !isLoggedIn ? <SignIn /> : <EditSeniorTeam {...rProps} />} />
              <Route path="/team/edit-team-member" render={rProps => !isLoggedIn ? <SignIn /> : <EditAdditionalTeam {...rProps} />} />


              <Route path="/team/add-additional-team" render={() => !isLoggedIn ? <SignIn /> : <AddAdditionalTeam />} />

              <Route path="/properties/add-property" render={() => !isLoggedIn ? <SignIn /> : <AddProperty />} />
              <Route path="/properties/edit-property" render={rProps => !isLoggedIn ? <SignIn /> : <EditProperty {...rProps} />} />
              <Route path="/properties/edit-blurb" render={rProps => !isLoggedIn ? <SignIn /> : <EditAllProperitesBlub {...rProps} />} />


              <Route path="/users/my-account" render={() => !isLoggedIn ? <SignIn /> : <MyAccount />} />


           
                  </Switch>
          </Container>  
          <Footer />

      </div>
    </Router>
  );
}
