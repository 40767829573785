import firebase from "firebase";

export const companyMethods = {
  updateAbout: (content) => {
    firebase
      .firestore()
      .collection("pages")
      .doc("about")
      .update({
        content: content,
      })
      .then(async (res) => {
        window.location.reload();

      })
      .catch((err) => {
        console.log(err);
      });
  },
  getAboutContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("about")
      .get();
    return data.data();
  },
  getCareersContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("careers")
      .get();
    return data.data();
  },
  getTenantsContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("tenants")
      .get();
    return data.data();
  },
  getContactContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("contact")
      .get();
    return data.data();
  },
  getCovidContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("covid")
      .get();
    return data.data();
  },

  updateCareer: (content) => {
    firebase
      .firestore()
      .collection("pages")
      .doc("careers")
      .update({
        content: content,
      })
      .then(async (res) => {
        window.location.reload();


      })
      .catch((err) => {
        console.log(err);
      });
  },
  getCareerContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("careers")
      .get();
    return data.data();
  },
  updateContact: (content) => {
    firebase
      .firestore()
      .collection("pages")
      .doc("contact")
      .update({
        content: content,
      })
      .then(async (res) => {
        window.location.reload();


      })
      .catch((err) => {
        console.log(err);
      });
  },
  updateTenants: (content) => {
    firebase
      .firestore()
      .collection("pages")
      .doc("tenants")
      .update({
        content: content,
      })
      .then(async (res) => {
        window.location.reload();

      })
      .catch((err) => {
        console.log(err);
      });
  },
  updateAllPropertiesBlurbContent: (content) => {
    firebase
      .firestore()
      .collection("pages")
      .doc("allPropertiesBlurb")
      .update({
        content: content,
      })
      .then(async (res) => {
        window.location.reload();

      })
      .catch((err) => {
        console.log(err);
      });
  },
  getAllPropertiesBlurbContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("allPropertiesBlurb")
      .get();
    return data.data();
  },
  updateSeniorTeam: (content) => {
    firebase
      .firestore()
      .collection("pages")
      .doc("seniorTeam")
      .update({
        content: content,
      })
      .then(async (res) => {
        window.location = "/#/team/all-team-members";
        
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getSeniorTeamContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("seniorTeam")
      .get();
    return data.data();
  },
  updateAdditionalTeam: (content) => {
    firebase
      .firestore()
      .collection("pages")
      .doc("additionalTeam")
      .update({
        content: content,
      })
      .then(async (res) => {
        window.location = "/#/team/all-team-members";

      })
      .catch((err) => {
        console.log(err);
      });
  },
  getAdditionalContent: async () => {
    const data = await firebase
      .firestore()
      .collection("pages")
      .doc("additionalTeam")
      .get();
    return data.data();
  },
  addNewProperty: async (property) => {
    const storageFolder = "/properties/";
    const newPhotos = [];
    const files = property.imageFiles;

    for (const file of files) {
      const randomString = Math.random().toString(36).slice(2);
      const fileName = randomString+file.name;
      const storageRef = firebase.storage().ref(storageFolder + fileName);
      
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            resolve(xhr.response);
        };
        xhr.responseType = 'blob';
        xhr.open('GET', file.previewUrl, true);
        xhr.send(null);
    });
    
     await storageRef.put(blob).then(function (snapshot) {
         storageRef
          .getDownloadURL()
          .then((url) => {
            newPhotos.push(url);
          })
          .then(() => {
            property["photos"] = newPhotos;
            firebase
                  .firestore()
                  .collection("properties")
                  .doc(property.propertyID)
                  .set(property)
                  .then(async (res) => {
                    window.location = "/#/all-properties";
                  })
                  .catch((err) => {
                    console.log(err);
                  });
          });
      });
    }
  },
  getAllProperties: async () => {
    const data = await firebase
      .firestore()
      .collection("properties")
      .get();
    return  data.docs.map(doc => doc.data());
  },
  getProperty: async (name) => {
    const data = await firebase
      .firestore()
      .collection("properties")
      .doc(name)
      .get();
    return data.data();
  },
   updateProperty: async (property, propertyID) => {
     console.log(property);
    const storageFolder = "/properties/";
    let newPhotos = [];
    const files = property.imageFiles;
    newPhotos = newPhotos.concat(property["uploadedPhotos"]);
    console.log(newPhotos);

    for (const file of files) {
      console.log('hello');
      const randomString = Math.random().toString(36).slice(2);
      const fileName = randomString+file.name;
      const storageRef = firebase.storage().ref(storageFolder + fileName);
      
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            resolve(xhr.response);
        };
        xhr.responseType = 'blob';
        xhr.open('GET', file.previewUrl, true);
        xhr.send(null);
    });
    
     await storageRef.put(blob).then(function (snapshot) {
         storageRef
          .getDownloadURL()
          .then((url) => {
            newPhotos.push(url);
            console.log(newPhotos);
            console.log(url);
          }).then(() => {
            console.log(property);
            property["photos"] = newPhotos;
            console.log(newPhotos);
    
            firebase
                  .firestore()
                  .collection("properties")
                  .doc(propertyID)
                  .update(property)
                  .then(async (res) => {
                    window.location = "/#/all-properties";
                  })
                  .catch((err) => {
                    console.log(err);
                  });
          });;
      })

    }
    if(property.imageFiles.length === 0){
      firebase
      .firestore()
      .collection("properties")
      .doc(propertyID)
      .update(property)
      .then(async (res) => {
        window.location = "/#/all-properties";
      })
      .catch((err) => {
        console.log(err);
      });
    }
  },
  deleteProperty: async (propertyID) => {
    await firebase.firestore().collection("properties").doc(propertyID).delete().then(async(res)  =>{
      window.location.reload();

  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });
  
  },
  deleteTeamMember: async (teamMemberID) => {
    await firebase.firestore().collection("team").doc(teamMemberID).delete().then(async(res)  =>{
      window.location.reload();

  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });
  
  },
  deleteSeniorTeamMember: async (teamMemberID) => {
    await firebase.firestore().collection("seniorTeam").doc(teamMemberID).delete().then(async(res)  =>{
      window.location.reload();

  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });
  
  },
  deletePhoto: (photo, propertyID, photos) => {
    const updatedPhotos = photos.filter(p => p !== photo);
      firebase
      .firestore()
      .collection("properties")
      .doc(propertyID)
      .update({photos : updatedPhotos})
      .then(async (res) => {})
      .catch((err) => {
        console.log(err);
      })
  },
  addNewSeniorTeamMember: async (teamMember) => {
    const storageFolder = "/seniorTeam/";
    const newPhotos = [];
    const files = teamMember.imageFiles;

    for (const file of files) {
      const randomString = Math.random().toString(36).slice(2);
      const fileName = randomString+file.name;
      const storageRef = firebase.storage().ref(storageFolder + fileName);
      
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            resolve(xhr.response);
        };
        xhr.responseType = 'blob';
        xhr.open('GET', file.previewUrl, true);
        xhr.send(null);
    });
    
     await storageRef.put(blob).then(function (snapshot) {
         storageRef
          .getDownloadURL()
          .then((url) => {
            newPhotos.push(url);
          })
          .then(() => {
            teamMember["photos"] = newPhotos;
            firebase
                  .firestore()
                  .collection("seniorTeam")
                  .doc(teamMember.teamMemberID)
                  .set(teamMember)
                  .then(async (res) => {
                    window.location = "/#/team/all-team-members";
                  })
                  .catch((err) => {
                    console.log(err);
                  });
          });
      });
    }
  },
  addNewAdditionalTeamMember: async (teamMember) => {
    const storageFolder = "/team/";
    const newPhotos = [];
    const files = teamMember.imageFiles;

    for (const file of files) {
      const randomString = Math.random().toString(36).slice(2);
      const fileName = randomString+file.name;
      const storageRef = firebase.storage().ref(storageFolder + fileName);
      
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            resolve(xhr.response);
        };
        xhr.responseType = 'blob';
        xhr.open('GET', file.previewUrl, true);
        xhr.send(null);
    });
    
     await storageRef.put(blob).then(function (snapshot) {
         storageRef
          .getDownloadURL()
          .then((url) => {
            newPhotos.push(url);
          })
          .then(() => {
            teamMember["photos"] = newPhotos;
            firebase
                  .firestore()
                  .collection("team")
                  .doc(teamMember.teamMemberID)
                  .set(teamMember)
                  .then(async (res) => {
                    window.location = "/#/team/all-team-members";
                  })
                  .catch((err) => {
                    console.log(err);
                  });
          });
      });
    }
  },
  getSeniorTeam: async () => {
    const data = await firebase
      .firestore()
      .collection("seniorTeam")
      .get();
    return  data.docs.map(doc => doc.data());
  },
  getAdditionalTeam: async () => {
    const data = await firebase
      .firestore()
      .collection("team")
      .get();
    return  data.docs.map(doc => doc.data());
  },
  getSeniorTeamMember: async (name) => {
    const data = await firebase
      .firestore()
      .collection("seniorTeam")
      .doc(name)
      .get();
    return data.data();
  },
  updateSeniorTeamMember: async (teamMember, teamMemberID) => {
    console.log(teamMember);
   const storageFolder = "/seniorTeam/";
   let newPhotos = [];
   const files = teamMember.imageFiles;
   newPhotos = newPhotos.concat(teamMember["uploadedPhotos"]);


   for (const file of files) {
     const randomString = Math.random().toString(36).slice(2);
     const fileName = randomString+file.name;
     const storageRef = firebase.storage().ref(storageFolder + fileName);
     
     const blob = await new Promise((resolve, reject) => {
       const xhr = new XMLHttpRequest();
       xhr.onload = () => {
           resolve(xhr.response);
       };
       xhr.responseType = 'blob';
       xhr.open('GET', file.previewUrl, true);
       xhr.send(null);
   });
   
    await storageRef.put(blob).then(function (snapshot) {
        storageRef
         .getDownloadURL()
         .then((url) => {
           newPhotos.push(url);
         }).then(() => {
           teamMember["photos"] = newPhotos;
   
           firebase
                 .firestore()
                 .collection("seniorTeam")
                 .doc(teamMemberID)
                 .update(teamMember)
                 .then(async (res) => {
                   window.location = "/#/team/all-team-members";
                 })
                 .catch((err) => {
                   console.log(err);
                 });
         });;
     })

   }
   if(teamMember.imageFiles.length === 0){
     firebase
     .firestore()
     .collection("seniorTeam")
     .doc(teamMemberID)
     .update(teamMember)
     .then(async (res) => {
       window.location = "/#/team/all-team-members";
     })
     .catch((err) => {
       console.log(err);
     });
   }
 },
 getAdditionalTeamMember: async (name) => {
  const data = await firebase
    .firestore()
    .collection("team")
    .doc(name)
    .get();
  return data.data();
},
updateAdditionalTeamMember: async (teamMember, teamMemberID) => {
  console.log(teamMember);
 const storageFolder = "/team/";
 let newPhotos = [];
 const files = teamMember.imageFiles;
 newPhotos = newPhotos.concat(teamMember["uploadedPhotos"]);


 for (const file of files) {
   const randomString = Math.random().toString(36).slice(2);
   const fileName = randomString+file.name;
   const storageRef = firebase.storage().ref(storageFolder + fileName);
   
   const blob = await new Promise((resolve, reject) => {
     const xhr = new XMLHttpRequest();
     xhr.onload = () => {
         resolve(xhr.response);
     };
     xhr.responseType = 'blob';
     xhr.open('GET', file.previewUrl, true);
     xhr.send(null);
 });
 
  await storageRef.put(blob).then(function (snapshot) {
      storageRef
       .getDownloadURL()
       .then((url) => {
         newPhotos.push(url);
       }).then(() => {
         teamMember["photos"] = newPhotos;
 
         firebase
               .firestore()
               .collection("team")
               .doc(teamMemberID)
               .update(teamMember)
               .then(async (res) => {
                 window.location = "/#/team/all-team-members";
               })
               .catch((err) => {
                 console.log(err);
               });
       });;
   })

 }
 if(teamMember.imageFiles.length === 0){
   firebase
   .firestore()
   .collection("team")
   .doc(teamMemberID)
   .update(teamMember)
   .then(async (res) => {
     window.location = "/#/team/all-team-members";
   })
   .catch((err) => {
     console.log(err);
   });
 }
},
getTeamContent: async () => {
  const data = await firebase
    .firestore()
    .collection("pages")
    .doc("team")
    .get();
  return data.data();
},
updateTeam: (content) => {
  firebase
    .firestore()
    .collection("pages")
    .doc("team")
    .update({
      content: content,
    })
    .then(async (res) => {
      window.location.reload();

    })
    .catch((err) => {
      console.log(err);
    });
},
};
